import "$styles/index.css";
import Alpine from "alpinejs";
import * as Turbo from "@hotwired/turbo"

/**
 * Adds support for declarative shadow DOM. Requires your HTML <head> to include:
 * `<meta name="turbo-cache-control" content="no-cache" />`
 */
import * as TurboShadow from "turbo-shadow"

/**
 * Uncomment the line below to add transition animations when Turbo navigates.
 * Use data-turbo-transition="false" on your <main> element for pages where
 * you don't want any transition animation.
 */
// import "./turbo_transitions.js"

// Import sliderData and audioPlayer after Alpine is loaded
import sliderData from "./slider";
import audioPlayer from "./audio_player";

// Register Alpine components
Alpine.data("sliderData", sliderData);
Alpine.data("audioPlayer", audioPlayer);

// Start Alpine.js
Alpine.start();

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}";
