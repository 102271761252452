export default function audioPlayer(playlist = []) {
  return {
    playing: false,
    muted: false,
    currentTime: 0,
    duration: 0,
    episode: playlist[0] || { id: "", title: "", audio_src: "" }, // Start with the first episode
    playbackRate: 1,
    playerRef: null,
    currentIndex: 0, // Index of the currently playing episode
    playlist: playlist, // The array of all episodes

    init() {
      this.playerRef = this.$refs.audio;

      if (!this.playerRef) {
        console.error("playerRef could not be initialized.");
      } else {
        console.log("playerRef initialized successfully.");

        // Load metadata and update duration
        this.playerRef.addEventListener("loadedmetadata", () => {
          this.duration = Math.floor(this.playerRef.duration);
        });

        // Play event listener
        this.playerRef.addEventListener("play", () => {
          this.playing = true;
          this.$dispatch("audio-playing", { id: this.episode.id });
        });

        // Pause event listener
        this.playerRef.addEventListener("pause", () => {
          this.playing = false;
          this.$dispatch("audio-paused", { id: this.episode.id });
        });

        // Time update listener
        this.playerRef.addEventListener("timeupdate", () => {
          this.currentTime = Math.floor(this.playerRef.currentTime);
        });

        // Listen for play-episode event to play new episodes
        document.addEventListener("play-episode", (event) => {
          const { id, title, audio_src } = event.detail;
          if (audio_src) {
            this.playNewEpisode({ id, title, audio_src });
          } else {
            console.error("No valid audio URL provided!");
          }
        });

        // Listen for toggle-play-pause event
        document.addEventListener("toggle-play-pause", () => {
          if (this.playing) {
            this.pause();
          } else {
            this.play();
          }
        });
      }
    },

    playNewEpisode({ id, title, audio_src }) {
      this.episode = { id, title, audio_src };
      this.currentTime = 0;
      this.duration = 0;

      this.playerRef.src = audio_src;
      this.playerRef.load();

      this.playerRef.addEventListener(
        "loadedmetadata",
        () => {
          this.duration = Math.floor(this.playerRef.duration);
          this.play();
        },
        { once: true },
      );
    },

    // Method to play the next episode
    playNextEpisode() {
      if (this.currentIndex < this.playlist.length - 1) {
        this.currentIndex++;
        const nextEpisode = this.playlist[this.currentIndex];
        this.playNewEpisode(nextEpisode);
      }
    },

    // Method to play the previous episode
    playPreviousEpisode() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        const previousEpisode = this.playlist[this.currentIndex];
        this.playNewEpisode(previousEpisode);
      }
    },

    play() {
      this.playerRef.play().catch((error) => {
        console.error("Play failed:", error);
      });
    },

    pause() {
      this.playerRef.pause();
    },

    toggleMute() {
      this.muted = !this.muted;
      this.playerRef.muted = this.muted;
    },

    seek(time) {
      this.playerRef.currentTime = time;
    },

    seekBy(amount) {
      this.playerRef.currentTime += amount;
    },
  };
}
