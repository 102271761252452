export default function sliderData() {
  return {
    thumbValue: 0,
    currentTime: 0,
    duration: 0,
    audioElement: null,

    init() {
      this.audioElement = this.$refs.audio;

      if (this.audioElement) {
        console.log("Audio element found:", this.audioElement);

        // Update duration when metadata is loaded
        this.audioElement.addEventListener("loadedmetadata", () => {
          this.duration = this.audioElement.duration;
        });

        // Update current time and thumb value as the audio plays
        this.audioElement.addEventListener("timeupdate", () => {
          this.currentTime = this.audioElement.currentTime;
          this.thumbValue = this.currentTime / this.duration;
        });
      } else {
        console.error("Audio element not found.");
      }
    },

    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes}:${secs.toString().padStart(2, "0")}`;
    },
  };
}
